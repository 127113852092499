import { ChevronRightIcon } from '@heroicons/react/outline';
import { jsonLdScriptProps } from 'react-schemaorg';
import ContentHeadline from './headline';

export default function Faq({ item }) {
  return (
    <>
      <script
        {...jsonLdScriptProps(
          {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: item.content.items.map((i) => ({
              '@type': 'Question',
              name: i.header,
              acceptedAnswer: {
                '@type': 'Answer',
                text: i.bodytext,
              },
            })),
          },
        )}
      />

      <div className="bg-white">
        <ContentHeadline after="Fragen">Häufig gestellte</ContentHeadline>
        <div className="mt-6">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
            {item.content.items.map((faq, k) => (
              <div key={faq.header + k}>
                <dt className="text-lg leading-6 font-medium text-gray-900 flex flex-nowrap">
                  <ChevronRightIcon
                    className="w-5 -ml-1 mr-1 text-action flex-shrink-0"
                  />
                  {faq.header}
                </dt>
                <dd
                  className="mt-2 text-base text-gray-500 pl-5 flex-grow"
                  dangerouslySetInnerHTML={{ __html: faq.bodytext }}
                />
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
}
