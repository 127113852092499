import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import moment from 'moment';
import Button from '@/components/elements/button';
import DatePicker from '@/components/elements/date-picker';
import BoatTypeSelect from '@/components/search/form/boat-type-select';
import SearchFormCabin from '@/components/search/form/cabin';
import SearchFormRegion from './form/region';
import SearchFormDuration from './form/duration';
import Checkbox from '../elements/checkbox';
import appConfig from '../../../app.config';
import defaultDate from '../../utils/defaultDate';
import {
  createMinMaxParam, extractMinMaxParam, MAX_VALUE, MIN_VALUE,
} from '../../utils/minMaxApiParam';

export default function SearchTopForm({ filter, onChangeAdvancedFilter, basements = null }) {
  const router = useRouter();
  const { t } = useTranslation('boat');

  const buildMockLocationsObj = (id) => {
    if (id !== '' && id !== undefined) {
      return {
        id,
      };
    }
    return null;
  };

  const [country, setCountry] = useState(buildMockLocationsObj(filter?.country));
  const [region, setRegion] = useState(buildMockLocationsObj(filter?.region_id));
  const [locationId, setLocationId] = useState(filter.location_id || '');
  const [sd, setSd] = useState(filter.sd || '');
  const [triggerRebuildRegion, setTriggerRebuildRegion] = useState(null);

  const {
    formState: { errors }, clearErrors, register, handleSubmit, getValues, setValue,
  } = useForm({
    // notice: default values may be overridden by useEffect watching for [filter]
    defaultValues: {
      sd: filter.sd || '',
      country: filter.country || '',
      region_id: filter.region_id || '',
      location_id: filter.location_id || '',
      date_from: filter?.date_from?.length > 0 ? filter.date_from : defaultDate(),
      date_to: filter.date_to || '',
      duration: filter.duration || '7',
      adac: (`${filter.adac}`) === '1' ? 1 : 0,
      boat_type_id: filter.boat_type_id,
    },
  });

  register('country', { required: 'Bitte wählen Sie ein Fahrgebiet aus.' });

  const [cabinMin, setCabinMin] = useState(NaN);
  const [cabinMax, setCabinMax] = useState(NaN);

  useEffect(() => {
    Object.keys(filter).forEach((key) => {
      const value = filter[key];
      setValue(key, value);
    });
    if (filter?.sd !== sd || filter?.country !== country?.id || filter?.region !== region?.id) {
      setSd(filter.sd || '');
      setCountry(buildMockLocationsObj(filter?.country));
      setRegion(buildMockLocationsObj(filter?.region_id));
      setLocationId(filter.location_id || '');
      setTriggerRebuildRegion(Date.now());
    }

    setCabinMin(extractMinMaxParam(filter.cabins, MIN_VALUE));
    setCabinMax(extractMinMaxParam(filter.cabins, MAX_VALUE));
  }, [filter]);

  // reset state on unmount
  useEffect(() => () => {
    setCountry(null);
    setRegion(null);
    setLocationId('');
    setSd('');
  }, []);

  const onSubmit = () => {
    console.log('Search called');
    const formValues = getValues();

    console.log(formValues);

    if (formValues.animals === 0) {
      formValues.animals = '';
    }

    if (formValues.date_from === '' || !moment(formValues.date_from).isValid()) {
      // formValues.date_from = moment().format('YYYY-MM-DD');
    }

    if (formValues.date_from !== '' || moment(formValues.date_from).isValid()) {
      formValues.date_to = moment(formValues.date_from).add(3, 'weeks').format('YYYY-MM-DD');
    }

    const cabinParam = createMinMaxParam(cabinMin, cabinMax);
    if (cabinParam) {
      formValues.cabins = cabinParam;
    }

    const query = {
      ...router.query,
      ...formValues,
      page: 1,
    };

    const uniformFields = (field) => {
      if (field === null || field === undefined || field === '') return null;
      return `${field}`;
    };

    if (router.query?.slug?.length > 1
      && basements === null
      && (uniformFields(formValues?.country) !== uniformFields(filter?.country)
        || uniformFields(formValues?.region_id) !== uniformFields(filter?.region_id)
        || uniformFields(formValues?.location_id) !== uniformFields(filter?.location_id)
      )) {
      router.push({
        pathname: appConfig.primarySearchPage,
        hash: 'search-content',
        query,
      });
    } else {
      router.push({
        hash: 'search-content',
        query,
      });
    }
  };

  return (
    <div
      className="z-20 bg-action-500 mb-7 lg:mb-0"
    >
      <div className="relative">
        <div className="container max-w-screen-xl mx-auto p-4">
          <p className="text-2xl font-semibold mt-0 mb-3 text-primary">Suche</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-y-4 sm:grid-cols-4 grid-cols-1 lg:grid-cols-1 gap-x-2"
            data-cy="search-form"
          >

            <div className="sm:col-span-2">

              <SearchFormRegion
                className="relative pl-10 pr-5 h-12 w-full block rounded truncate z-20"
                setValue={(v) => {
                  setValue('sd', v);
                  setSd(v);
                }}
                sd={sd}
                errors={errors}
                country={country}
                region={region}
                locationId={locationId}
                interacted={() => clearErrors('country')}
                setCountry={(v) => {
                  setValue('country', v?.country_id || '');
                  setCountry(v);
                }}
                setRegion={(v) => {
                  setValue('region_id', v?.region_id || '');
                  setRegion(v);
                }}
                setLocationId={(v) => {
                  setValue('location_id', v || '');
                  setLocationId(v);
                }}
                next={() => {
                }}
                reInitialize={triggerRebuildRegion}
                basements={basements}
              />
            </div>
            <div className="sm:col-span-2">
              <DatePicker
                inputDate={getValues('date_from')}
                setValue={(field, value) => {
                  setValue('date_from', value);
                }}
                onDateChange={() => {
                }}
              />
            </div>
            <div className="sm:col-span-2">
              <SearchFormDuration
                form={getValues()}
                value={getValues('duration')}
                setValue={(field, value) => setValue(field, value)}
                next={() => {
                }}
              />
            </div>
            <div className="sm:col-span-2">
              <BoatTypeSelect
                value={getValues('boat_type_id')}
                setValue={(field, value) => setValue(field, value)}
              />
            </div>
            <div className="sm:col-span-2">
              <SearchFormCabin
                cabinMin={cabinMin}
                cabinMax={cabinMax}
                onCabinChange={(min, max) => {
                  setCabinMin(min);
                  setCabinMax(max);
                }}
              />
            </div>

            <div className="sm:col-span-2">
              <Checkbox
                value={getValues('adac')}
                label={(
                  <span>
                    Ich bin
                    {' '}
                    <b>ADAC Mitglied</b>
                  </span>
                )}
                name="adac"
                setValue={(v) => {
                  setValue('adac', v);
                  onSubmit();
                }}
              />
            </div>

            <div className="sm-col-span-1 lg:hidden">
              <div
                onClick={() => onChangeAdvancedFilter()}
                className="cursor-pointer border border-transparent text-center appearance-none select-none bg-gray-500 text-white hover:text-white hover:bg-gray-600 active:bg-gray-600 text-base px-6 w-full h-11 leading-11"
              >
                Weitere Filter
              </div>
            </div>
            <div className="sm:col-span-3">
              <Button
                type="submit"
                width="w-full"
                variant="primary"
                dataCy="submit-search"
              >
                {t('Search')}
              </Button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}
