import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import Input from 'components/elements/input';
import RangeSlider from 'components/elements/range-slider';
import InputThruster from 'components/inputs/thruster';
import Button from '@/components/elements/button';
import { useEffect, useState } from 'react';
import InputSail from '@/components/inputs/sail';
import InputConstructionYear from '@/components/inputs/construction-year';
import Select from '@/components/elements/select';
import CloseIcon from '../../../public/assets/icons/cross-out.svg';
import {
  createMinMaxParam, extractMinMaxParam, MAX_VALUE, MIN_VALUE,
} from '../../utils/minMaxApiParam';

export default function SearchFilter({
  filter, onFilterChange, showFilter, onChangeShowFilter,
}) {
  const router = useRouter();

  const { t } = useTranslation('boat');

  useEffect(() => {
    if (showFilter) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [showFilter]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  useEffect(() => {
    setMinPrice(extractMinMaxParam(filter.price, MIN_VALUE));
    setMaxPrice(extractMinMaxParam(filter.price, MAX_VALUE));
  }, [filter]);

  const {
    handleSubmit, getValues, setValue, register,
  } = useForm({
    defaultValues: {
      search: router.query.q ?? '',
    },
  });
  const onSubmit = (data) => {
    const filter = {
      q: data.search,
      price: createMinMaxParam(minPrice, maxPrice),
    };

    onFilterChange(filter);
    if (showFilter) {
      onChangeShowFilter();
    }
  };

  const onSliderChange = (name, min, max) => {
    onFilterChange(name, `btw:${min},${max}`);
  };

  const getFilterMinValueFromRange = (filterName, defaultValue) => {
    const match = filter[filterName]?.match(/:(\d*),/);
    if (match?.length === 2) {
      return Number(match[1]);
    }
    return defaultValue;
  };

  const [sorting, setSorting] = useState(filter.sort);

  const onChangeSorting = (sort) => {
    setSorting(sort);
    onFilterChange('sort', sort);
  };

  const onChangeMinPrice = (price) => {
    setMinPrice(price);
  };

  const onChangeMaxPrice = (price) => {
    setMaxPrice(price);
  };

  const getFilterMaxValueFromRange = (filterName, defaultValue) => {
    const match = filter[filterName]?.match(/:\d*,(\d*)/);
    if (match?.length === 2) {
      return Number(match[1]);
    }
    return defaultValue;
  };

  return (

    <div
      className={`bg-gray-100 lg:mb-0 lg:block ${showFilter ? 'fixed left-0 top-0 w-full h-screen p-4 z-[10001] overflow-auto' : 'hidden'}`}
    >
      <div className="relative">
        <div className="container max-w-screen-xl mx-auto p-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-y-4 grid-cols-1"
          >
            <p className="text-2xl font-semibold mt-0">
              Filter
              <span className="lg:hidden w-6 h-6 float-right z-60" onClick={onChangeShowFilter}>
                <CloseIcon className="w-6 h-6 cursor-pointer" alt="Filtereinstellungen schließen" />
              </span>
            </p>
            <div className="">
              <fieldset className="border-b pb-2">
                <div>

                  <Input
                    register={{ ...register('search') }}
                    value={getValues('search')}
                    setValue={(v) => {
                      setValue('search', v);
                    }}
                    name="search"
                    label="Suchbegriff"
                    placeholder="Modell, Anbieter, Bootsname oder Nr."
                  />
                </div>
              </fieldset>

              <fieldset className="pb-2">
                <div className="flex gap-2 items-center">
                  <Input
                    type="number"
                    label="Preis ab"
                    register={{ onChange: ({ target }) => onChangeMinPrice(target.value), value: minPrice }}
                  />
                  <Input
                    type="number"
                    label="Preis bis"
                    register={{ onChange: ({ target }) => onChangeMaxPrice(target.value), value: maxPrice }}
                  />
                </div>
              </fieldset>

              <Button
                type="submit"
                width="w-full"
                size="sm"
                variant="action"
                data-cy="search-query-submit"
              >
                {t('Search')}
              </Button>
            </div>

            <div className="">
              <fieldset className="pb-2">
                <legend className="font-semibold mb-1 text-sm">Sortieren nach</legend>
                <div>
                  <Select
                    id="sorting"
                    name="sorting"
                    register={{ onChange: ({ target }) => onChangeSorting(target.value) }}
                    selected={sorting}
                    options={[
                      {
                        value: 'date_from',
                        label: 'Datum',
                      },
                      {
                        value: 'total_price',
                        label: 'Preis',
                      },
                      {
                        value: 'date_from,total_price',
                        label: 'Datum und Preis',
                      },
                    ]}
                  />
                </div>
              </fieldset>
            </div>
            <div className="">
              <fieldset className="pb-2 border-b">
                <legend className="font-semibold mb-1 text-sm">Badezimmer</legend>
                <div>
                  <RangeSlider
                    name="baths"
                    initialMin={getFilterMinValueFromRange('baths', 1)}
                    initialMax={getFilterMaxValueFromRange('baths', 6)}
                    min={1}
                    max={6}
                    step={1}
                    onChangeHandler={onSliderChange}
                  />
                </div>
              </fieldset>
            </div>
            <div className="hidden">
              <fieldset className="pb-2">
                <legend className="font-semibold mb-1 text-sm">Baujahr</legend>
                <div>

                  <RangeSlider
                    name="construction"
                    initialMin={getFilterMinValueFromRange('construction', 1980)}
                    initialMax={getFilterMaxValueFromRange('construction', 2022)}
                    min={1980}
                    max={2022}
                    step={3}
                    priceCap={1000}
                    onChangeHandler={onSliderChange}
                  />
                </div>
              </fieldset>
            </div>
            <div className="">
              <fieldset className="border-b pb-2">
                <legend className="font-semibold mb-1 text-sm">Baujahr</legend>
                <div>
                  <InputConstructionYear />
                </div>
              </fieldset>
            </div>
            <div className="">
              <fieldset className="border-b pb-2">
                <legend className="font-semibold mb-1 text-sm">Strahlruder</legend>
                <div>
                  <InputThruster />
                </div>
              </fieldset>
            </div>
            <div className="">
              <fieldset className="border-b pb-2">
                <legend className="font-semibold mb-1 text-sm">Hauptsegel</legend>
                <div>
                  <InputSail />
                </div>
              </fieldset>
            </div>
            <div className="pb-4">
              <fieldset className="pb-2">
                <legend className="font-semibold mb-1 text-sm">Länge</legend>
                <div>
                  <RangeSlider
                    name="length"
                    initialMin={getFilterMinValueFromRange('length', 0)}
                    initialMax={getFilterMaxValueFromRange('length', 50)}
                    min={0}
                    max={50}
                    step={2}
                    onChangeHandler={onSliderChange}
                    marks={{
                      0: '0m',
                      10: '10m',
                      20: '20m',
                      30: '30m',
                      40: '40m',
                      50: '50m',
                    }}
                  />
                </div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
