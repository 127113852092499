export function createMinMaxParam(min, max) {
  if (min > 0 && max > 0) {
    return `btw:${min},${max}`;
  }

  if (min > 0) {
    return `gte:${min}`;
  }

  if (max > 0) {
    return `lte:${max}`;
  }

  return undefined;
}

export function extractMinMaxParam(apiParam, regex) {
  const match = apiParam?.match(regex);
  if (match?.length === 2) {
    return Number(match[1]);
  }
  return undefined;
}

// noinspection RegExpUnnecessaryNonCapturingGroup
export const MIN_VALUE = /(?:(?:gte:)|(?:btw:))(\d+)/;
// noinspection RegExpUnnecessaryNonCapturingGroup
export const MAX_VALUE = /(?:(?:lte:)|(?:btw:\d+,))(\d+)/;
