import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function RangeSlider3({
  initialMin, initialMax, min, max, step, onChangeHandler, name, marks = false,
}) {
  return (
    <div>
      <div className="flex flex-col mt-4 relative">
        <Range
          min={min}
          max={max}
          step={step}
          defaultValue={[initialMin, initialMax]}
          allowCross={false}
          onAfterChange={([min, max]) => {
            onChangeHandler(name, min, max);
          }}
          trackStyle={[{ backgroundColor: 'rgb(22, 163, 74)' }]}
          handleStyle={[{ border: '1px solid gray', width: '1.125rem', height: '1.125rem' }]}
          dotStyle={{ border: '1px solid gray' }}
          marks={marks}
        />
      </div>
      {marks === false && (
        <div className="flex text-sm mt-2">
          <span>{min}</span>
          <span className="ml-auto">{max}</span>
        </div>
      )}
    </div>
  );
}
