import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import moment from 'moment';

export default function OfferCatalogSchema({ items }) {
  const offers = () => items.map((item) => ({
    '@type': ['Product', 'Yacht'],
    name: item.boat.title,
    availability: 'https://schema.org/InStock',
    description: item.boat.description,
    sku: item.boat.id,
    mpn: item.boat.id,
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/yacht/${item.boat.slug}`,
    offers: [{
      price: item.boat.min_price,
      priceCurrency: 'EUR',
      priceValidUntil: moment().format('YYYY-MM-DD'),
    }],
  }));

  return (
    <Head>
      <script
        {...jsonLdScriptProps(
          [
            {
              '@context': 'https://schema.org/',
              '@type': ['Product', 'Yacht'],
              serviceType: 'Boat charter',
              brand: {
                '@type': 'Brand',
                name: 'CharterCheck',
              },
              hasOfferCatalog: {
                '@type': 'OfferCatalog',
                name: 'Boat offers',
                itemListElement: [
                  offers(),
                ],
              },
            },
          ],
        )
        }
      />
    </Head>
  );
}
