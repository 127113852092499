import { ChevronDownIcon } from '@heroicons/react/solid';

export default function Select({
  children, label, id, register, error, name, selected, mainClass = '', options = [], primary = false,
}) {
  const okLabelColor = primary
    ? 'text-gray-600'
    : 'text-primary';
  const labelColor = error ? 'text-red-600' : okLabelColor;

  return (
    <div
      className={`${mainClass} relative border border-1 ${primary ? 'bg-white border-gray-100' : 'bg-gray-100 border-gray-400'} group w-full transition-all overflow-hidden`}
    >
      <select
        {...register}
        className={`input-text z-[3] rounded-none relative block w-full px-2 ${label ? 'py-1 pt-5' : 'py-2'} bg-transparent border-b-2 border-0 border-transparent group-hover:border-secondary focus:outline-none focus:border-primary ring-0 ${error ? 'border-red-600s' : ''}`}
        id={id}
        name={name}
        defaultValue=""
      >
        {options.length ? (
          options.map((option, index) => (
            <option key={index} value={option.value} selected={option.value === selected}>{option.label}</option>
          ))
        ) : children}
      </select>
      {label && (
        <label
          className={`absolute top-1 z-10 block w-9/10 px-2 text-xs font-bold ${labelColor}`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-5 fill-gray-400" />
    </div>
  );
}
