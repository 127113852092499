import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import appConfig from '../../../app.config';

const settings = [
  { name: 'beliebig', query: '' },
  { name: 'weniger als 3 Jahre', query: `gt:${dayjs().subtract(3, 'year').format('YYYY')}` },
  { name: 'weniger als 5 Jahre', query: `gt:${dayjs().subtract(5, 'year').format('YYYY')}` },
  { name: 'weniger als 10 Jahre', query: `gt:${dayjs().subtract(10, 'year').format('YYYY')}` },
  { name: 'mehr als 10 Jahre', query: `lt:${dayjs().subtract(10, 'year').format('YYYY')}` },
];
const queryName = 'construction';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function InputConstructionYear() {
  const router = useRouter();
  const preSelectFilterValueFromUrlQuery = () => {
    const { query } = router;
    let index = 0;
    if (query[queryName]) {
      index = settings.findIndex((elem) => elem.value === query[queryName]);
    }
    return index;
  };

  const [selected, setSelected] = useState(settings[preSelectFilterValueFromUrlQuery()]);

  const onChangeSelection = (item) => {
    setSelected(item);
    const { query } = router;
    query[queryName] = item.query;
    query.page = '1';
    router.push({
      pathname: appConfig.primarySearchPage,
      query,
    });
  };

  return (
    <RadioGroup value={selected} onChange={onChangeSelection} className="-mx-4">
      <RadioGroup.Label className="sr-only">Strahlruder</RadioGroup.Label>
      <div className=" rounded-md -space-y-px">
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({ checked }) => classNames(
              settingIdx === 0 ? '' : '',
              settingIdx === settings.length - 1 ? '' : '',
              checked ? 'bg-action-100 border-primary-200 z-10' : 'border-gray-200',
              'relative px-4 py-2.5 flex cursor-pointer focus:outline-none',
            )}
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full border flex items-center justify-center',
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? 'text-primary-900' : 'text-gray-900', 'block text-sm font-medium')}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
