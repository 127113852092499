import Select from '@/components/elements/select';

export default function SearchFormCabin({
  cabinMin, cabinMax, onCabinChange, lowerBound = 1, upperBound = 7,
}) {
  const generateOptionsBetween = (min, max) => {
    const options = [{ value: undefined, label: '' }];
    for (let i = min; i <= max; i += 1) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  return (
    <div className="kojen flex gap-4">
      <Select
        mainClass="flex-1"
        label="Kabinen von"
        primary
        selected={Number(cabinMin)}
        options={generateOptionsBetween(lowerBound, Number(cabinMax || upperBound))}
        register={{ onChange: ({ target }) => onCabinChange(Number(target.value), cabinMax) }}
      />
      <Select
        mainClass="flex-1"
        label="Kabinen bis"
        primary
        selected={Number(cabinMax)}
        options={generateOptionsBetween(Number(cabinMin || lowerBound), upperBound)}
        register={{ onChange: ({ target }) => onCabinChange(cabinMin, Number(target.value)) }}
      />
    </div>
  );
}
