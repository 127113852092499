import 'core-js/features/object/from-entries';
import { useEffect, useState } from 'react';
import Basement from 'components/search/basement';
import nProgress from 'nprogress';
import dayjs from 'dayjs';
import date from '@/lib/filters/date';
import OfferCatalogSchema from '@/components/search/offer-catalog-schema';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import SearchItem from './item';
import GotQuestions from './got-questions';
import SearchTopForm from './top-form';
import Pagination from '../elements/pagination';
import SearchFilter from './filter';
import { fetchAPI as connectApi } from '../../lib/connect';

export default function Search({
  data, meta, filter, content = '', setData, setMeta, basements = null,
}) {
  const router = useRouter();
  const [stateFilter, setStateFilter] = useState(filter);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [popupBasementId, setPopupBasementId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setStateFilter(filter);
  }, [filter]);

  useEffect(() => {
    const id = router.asPath.match(/#([a-z0-9]+)/gi);
    const content = document.getElementById('filter-animals'); // offset for search

    if (id && id[0] === '#search' && content && window.innerWidth < 870) {
      content.scrollIntoView();
    }
  }, []);

  const openBasementModal = (basementId) => {
    setPopupBasementId(basementId);

    setTimeout(() => {
      setIsModalOpen(true);
    }, 20);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);

    setTimeout(() => {
      setPopupBasementId(null);
    }, 200);
  };

  const isFirstResultLaterThanInputDate = () => {
    if (data.length > 0 && stateFilter.date_from !== '' && stateFilter.page < 2 && stateFilter.sort !== 'total_price') {
      const inputDate = dayjs(stateFilter.date_from);
      return inputDate.diff(data[0].date_from, 'day') !== 0;
    }
    return false;
  };

  const fetchResultsForFilter = (filters) => {
    const url = new URL(window.location);

    Object.entries(filters).forEach(([filterName, filterValue]) => {
      url.searchParams.set(filterName, filterValue);
      if (filterName !== 'page') {
        url.searchParams.set('page', '1');
      } else {
        url.searchParams.set('page', filterValue);
      }
      if (url.searchParams.get('group') === null) {
        url.searchParams.set('group', 'model');
      }

      if (filterValue === undefined) {
        url.searchParams.delete(filterName);
      }
    });

    router.push(url, undefined, { shallow: true });

    nProgress.start();

    const searchFilter = Object.assign(stateFilter, Object.fromEntries(url.searchParams.entries()));
    delete searchFilter.slug;

    setStateFilter({
      ...stateFilter,
      ...filters,
    });

    connectApi(
      '/search',
      searchFilter,
    ).then((result) => {
      setData(result?.data);
      setMeta(result.meta);
    }).finally(() => {
      const content = document.getElementById('search-content');
      if (content) {
        content.scrollIntoView();
      }
      nProgress.done();
    });
  };

  const fetchResults = (filterName, filterValue) => {
    fetchResultsForFilter({ [filterName]: filterValue });
  };

  const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false });

  return (
    <div
      className="max-w-7xl mx-auto w-full px-6 py-6 lg:py-4 xl:px-0 mt-header"
    >
      <div
        className="flex flex-col lg:flex-row lg:space-x-6"
      >
        <aside className="flex flex-col lg:w-1/4 lg:space-y-8">
          <SearchTopForm
            filter={stateFilter}
            onChangeAdvancedFilter={() => {
              setShowAdvancedFilter(!showAdvancedFilter);
            }}
            basements={basements}
          />
          <SearchFilter
            filter={stateFilter}
            onFilterChange={(filters) => {
              fetchResultsForFilter(filters);
            }}
            showFilter={showAdvancedFilter}
            onChangeShowFilter={() => {
              setShowAdvancedFilter(!showAdvancedFilter);
            }}
          />
          <GotQuestions />
        </aside>
        <main className="flex flex-col flex-1 pb-10 space-y-4 pt-[100px] mt-[-100px]" id="search-content">
          {content}
          <div className={`${content ? '' : 'md:mt-20'}`}>
            {isFirstResultLaterThanInputDate() && (
              <div className="alert alert-success text-sm" data-cy="search-notice-date">
                Leider stehen für den
                {' '}
                <b>
                  {(stateFilter?.date_from === undefined || stateFilter.date_from === '')
                    ? date(dayjs()) : date(stateFilter.date_from)}
                </b>
                {' '}
                keine Termine mehr zur Verfügung. Folgende alternative Termine stehen zur Auswahl:
              </div>
            )}
            <ReactTooltip id="adac-tooltip" effect="solid">
              Lieber ADAC Kunde,
              <br />
              bei Buchung dieses Bootes erhalten Sie - nach Eingabe Ihrer ADAC Kundennummer einen Sonderrabatt.
            </ReactTooltip>
            <ul className="flex flex-col space-y-3 lg:space-y-4" data-cy="search-results">
              {data.map((item) => (
                <li key={item.booking_query}>
                  {/* (k + 1) > 0 && (k + 1) % 10 === 0 ? <SearchDisrupter /> : '' */}

                  <SearchItem
                    item={item}
                    filter={stateFilter}
                    openBasementModal={openBasementModal}
                  />
                </li>
              ))}

            </ul>
            {meta.total > 0
              ? (
                <>
                  <OfferCatalogSchema items={data} />
                  <Pagination
                    perPage={meta.per_page}
                    current={meta.current_page}
                    total={meta.total}
                    paginate={(p) => {
                      fetchResults('page', p);
                    }}
                  />
                </>
              )
              : (
                <div className="bg-primary text-white p-3 text-center font-bold">
                  Leider konnten keine Ergebnisse gefunden
                  werden. Bitte ändern Sie das Datum oder die Filtereinstellungen.
                </div>
              )}
          </div>
        </main>
      </div>

      {popupBasementId && (
        <Basement open={isModalOpen} setOpen={onCloseModal} basementId={popupBasementId} />
      )}
    </div>
  );
}
