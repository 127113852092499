import dayjs from 'dayjs';

// eslint-disable-next-line import/no-anonymous-default-export
export default (val, format) => {
  const f = format || 'DD.MM.YYYY';
  if (!val || val.length === 0) {
    return '';
  }
  return dayjs(val, 'YYYY-MM-DD').format(f);
};
