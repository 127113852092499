import appConfig from '../../../app.config';
import Link from '../helper/link';

export default function GotQuestions() {
  return (
    <div className="hidden lg:flex flex-col items-center text-gray-700 p-4 text-center rounded shadow-sm border">

      <h2 className="text-lg font-bold text-gray-700">Fragen?</h2>
      <p className="mt-2">
        Wir sind hier, um Ihnen bei der Planung des perfekten Hausbooturlaubs zu helfen.
      </p>
      <address className="flex flex-col items-center w-full pt-4 space-y-5 not-italic">
        <span
          className="space-x-2 text-base"
        >
          <span>Telefon:</span>
          <a
            href={`tel:${appConfig.phone}`}
            className="text-primary-500 hover:text-action-500"
          >
            {appConfig.phone}
          </a>
        </span>
        <Link href="/kontakt">
          <a
            className="w-full py-2 text-base font-bold text-zz-blue-500 bg-white border-2 border-zz-blue-500 rounded-md hover:border-zz-turquoise-500 hover:text-zz-turquoise-500"
          >
            Kontaktieren Sie uns
          </a>
        </Link>
      </address>
    </div>
  );
}
