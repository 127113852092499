import { useEffect, useMemo, useState } from 'react';
import { fetchAPI } from 'lib/connect';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import parse from 'components/helper/image';
import { Dialog } from '@headlessui/react';
import Modal from '@/components/globals/modal';

function LoadingOrNotFound({ loading }) {
  return (loading ? (
    <>
      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 px-6 mb-4">
        Lädt...
      </Dialog.Title>
      <div className="text-primary p-6">
        Wir bitten im einen kurzen Moment Geduld.
      </div>
    </>
  ) : (
    <p>Die Information konnte leider nicht gefunden werden.</p>
  ));
}

export default function Basement({ open, setOpen, basementId }) {
  const [loading, setLoading] = useState(true);
  const [basement, setBasement] = useState(null);

  const BasementMap = useMemo(() => dynamic(
    () => import('components/search/basement-map'),
    { ssr: false, loading: () => <p>Loading</p> },
  ), []);

  const fetchData = () => {
    fetchAPI(`/basement/${basementId}?geojson=true&region=true`)
      .then((result) => {
        setBasement(result.data);
      }).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [basementId]);

  return (
    <Modal open={open} setOpen={setOpen} loading={loading}>
      {basement ? (
        <div className="pt-4 pb-4">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 px-6 mb-4">
            {basement.name}
          </Dialog.Title>
          <div className="text-primary">
            <BasementMap basement={basement} />
            <div className="p-4">
              {basement.photo_url && (
                <div className="w-[300px] h-[200px] float-right ml-4">
                  <Image
                    src={parse(basement.photo_url)}
                    layout="fixed"
                    objectFit="cover"
                    height="200"
                    width="300"
                    alt={basement.name}
                    title={basement.name}
                    style={{ float: 'right' }}
                  />
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: basement.description }} />
            </div>
          </div>
        </div>
      ) : (
        <LoadingOrNotFound loading={loading} />
      )}
    </Modal>
  );
}
