import { useRouter } from 'next/router';
import Image from 'next/image';

import { DetermineCharterLicense } from '@/components/boat/CharterLicenseNotice';
import { isAdacCustomer } from '@/lib/adac';
import DestinationIcon from '../../../public/assets/icons/anker/port.svg';
import Link from '../helper/link';
import parse from '../helper/image';
import price from '../../lib/filters/price';

import UserIcon from '../../../public/assets/icons/anker/user.svg';
import CabinIcon from '../../../public/assets/icons/anker/bed.svg';
import LengthIcon from '../../../public/assets/icons/anker/length.svg';
import ConstructionIcon from '../../../public/assets/icons/anker/construction.svg';
import PSIcon from '../../../public/assets/icons/anker/lightning.svg';
import CalendarIcon from '../../../public/assets/icons/anker/calendar.svg';
import MainSailIcon from '../../../public/assets/icons/anker/mainsail.svg';
import GenoaIcon from '../../../public/assets/icons/anker/genoa.svg';
import AnimalIcon from '../../../public/assets/icons/anker/dog.svg';
import date from '../../lib/filters/date';
import number from '../../lib/filters/number';

export default function SearchItem({ item, openBasementModal, filter }) {
  const router = useRouter();
  // eslint-disable-next-line no-mixed-operators
  const calculateDiscount = () => Math.floor(100 - (item.total_price / (item.original_price + item.surcharge) * 100));

  const buildLink = () => {
    const linkObj = {
      pathname: `/yacht/${item.boat.slug}`,
      query: {
        ...router.query,
        ...{ bq: item.booking_query },
      },
    };
    if (linkObj.query?.date_from === undefined || linkObj.query.date_from === '') {
      linkObj.query.date_from = item.date_from;
    }

    return linkObj;
  };

  const specs = [
    {
      icon: UserIcon,
      label: 'Kojen',
      value: `${item.boat.berth.fixed}${item.boat.berth.variable ? ` (+ ${item.boat.berth.variable})` : ''}`,
    },
    { icon: CabinIcon, label: 'Kabinen', value: item.boat.cabins },
    { icon: LengthIcon, label: 'Länge', value: `${number(item.boat.length)} m` },
    { icon: ConstructionIcon, label: 'Baujahr', value: item.boat.construction || '-' },
    {
      icon: PSIcon, label: 'PS', value: item.boat.ps || '-', className: 'hidden sm:block',
    },
    {
      icon: MainSailIcon, label: 'Hauptsegel', value: item.boat.sail_type || '-', className: 'hidden sm:block',
    },
    {
      icon: GenoaIcon, label: 'Genoa', value: item.boat.genoa_type || '-', className: 'hidden sm:block',
    },
    {
      icon: AnimalIcon,
      label: 'Tiermitname',
      value: item.boat.animals ? 'erlaubt' : 'auf Anfrage',
      className: 'col-span-2 sm:col-span-1',
    },
  ];

  const isLicenseNeeded = (item) => {
    const { isWithoutLicense } = DetermineCharterLicense(item.boat, item);
    return isWithoutLicense;
  };

  return (
    <div>
      <Link
        href={buildLink()}
      >
        <a
          className="flex flex-col justify-center space-y-4 text-primary-500 hover:text-primary-500"
        >
          <article className="self-center w-full overflow-hidden bg-white rounded-lg shadow group mb-3">
            <div
              className="relative w-full overflow-hidden sm:grid sm:grid-cols-5"
            >
              <div className="relative flex flex-col flex-no-wrap sm:flex-row sm:col-span-2">
                {item.boat?.photo_url
                  && (
                    <Image
                      src={parse(item.boat.photo_url)}
                      alt={item.boat.title}
                      title={item.boat.title}
                      height={200}
                      width={380}
                      objectFit="cover"
                    />
                  )}
                {calculateDiscount() > 0 && (
                  <div className="absolute left-0 top-0 bg-red-600 text-white px-2 py-2 font-bold text-lg md:text-xl">
                    -
                    {calculateDiscount()}
                    %
                  </div>
                )}
                {isAdacCustomer() && parseInt(filter.adac, 10) === 1 && (
                  <div className="absolute top-0 right-0">
                    <div className="flex flex-col items-end gap-1 w-[65px]" data-for="adac-tooltip">
                      <Image
                        title="ADAC Vorteil"
                        alt="ADAC Vorteil"
                        src="/images/adac.svg"
                        objectFit="contain"
                        height={65}
                        width={65}
                      />

                      <div className="text-adac-fg bg-adac-bg w-full p-1 text-center text-xs">
                        VORTEIL
                      </div>
                    </div>
                  </div>
                )}
                {isLicenseNeeded(item) && (
                  <div className="absolute bottom-0 bg-green-600 text-white py-1 w-full text-center opacity-90">
                    Mieten ohne Führerschein
                  </div>
                )}
              </div>
              <div className="sm:col-span-3">
                <header className="relative px-3 ">
                  <h2
                    className="relative text-base font-semibold pt-3 leading-6 text-primary-500 line-clamp-2 sm:text-lg border-b"
                  >
                    {item.boat.title}

                    <div
                      className="absolute right-0 top-1 z-[40]"
                      onClick={() => window.open(`/partner/${item.team.slug}`).focus()}
                    >
                      <Image
                        src={parse(item.team.photo_url)}
                        alt={item.team.name}
                        height={30}
                        width={80}

                        objectFit="contain"
                      />
                    </div>
                  </h2>
                  <div className="mt-1">
                    <p className="flex items-center font-semibold">
                      <CalendarIcon className="mr-2" width={14} />
                      {date(item.date_from)}
                      {' '}
                      -
                      {' '}
                      {date(item.date_to)}
                      <span
                        className="text-xs ml-2 leading-4 font-semibold bg-gray-100 rounded-xl text-primary-500 text-center py-0.5 px-2.5"
                      >
                        {item.duration}
                        {' '}
                        Nächte
                      </span>
                    </p>
                    <h3
                      className="flex items-center text-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        openBasementModal(item.basement_start.id);
                      }}
                    >
                      <DestinationIcon className="mr-2 ml-0.5" width={12} />
                      <span className="line-clamp-1">{item.basement_start.location?.half}</span>
                    </h3>
                  </div>
                </header>
                <ul
                  id="specs"
                  className="flex-1 grid grid-cols-3 sm:grid-cols-4 gap-x-4 gap-y-3 sm:gap-y-1 bg-gray-100 mt-4 px-3 py-2"
                >
                  {specs.map((v) => (

                    <li className={v.className || ''} key={v.label}>
                      <div className="flex items-center">
                        <div className="w-7">
                          <v.icon height={14} className="mr-2" />
                        </div>
                        <span className="text-sm font-semibold">{v.label}</span>
                      </div>
                      <div className="ml-7 text-sm">
                        {v.value}
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  id="price"
                  className="flex flex-col pt-3"
                >
                  <div className="flex flex-row items-end justify-end space-x-2 sm:flex-col sm:pt-0">
                    <div
                      className="hidden sm:block text-xs leading-4 font-semibold bg-gray-100 rounded-t mr-0 text-primary-500 text-center py-0.5 px-2.5 "
                    >
                      Preis für
                      {' '}
                      {item.duration}
                      {' '}
                      Nächte
                    </div>
                    <div className="flex">
                      <button
                        className="px-5 py-2 bg-gray-200 text-base font-bold leading-6 rounded-tl"
                      >
                        Yachtdetails &rarr;
                      </button>
                      <button
                        className="px-5 py-2 bg-primary rounded-tr text-right text-white"
                        data-cy="boat-page-button"
                      >
                        {item.original_price + item.surcharge !== item.total_price && (
                          <s className="block text-xs md:text-md text-red-600">
                            {price(item.original_price + item.surcharge)}
                          </s>
                        )}
                        <span className="font-bold leading-6 text-2xl">
                          {parseInt(filter.adac, 10) === 1
                            ? (
                              <>
                                {price(item.total_price * 0.975)}
                                <div className="font-bold leading-5 text-md mt-1 text-adac-fg">
                                  ADAC-Vorteilspreis
                                </div>
                              </>
                            ) : price(item.total_price)}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </Link>
    </div>
  );
}
